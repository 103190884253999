function clearDropzone() {
  const objDZ = Dropzone.forElement("#dZUpload");
  objDZ.emit("resetFiles");
}

function resetForm() {
  clearDropzone();
  $('#updateAttributesModal').modal('hide')
}

Dropzone.autoDiscover = false;

$(document).ready(async () => {
  let url = window.location.href.includes("product_catalogues") ? 'product_catalogues' : 'products';
  $("#dZUpload").dropzone({
    addRemoveLinks: true,
    autoProcessQueue: false,
    uploadMultiple: true,
    parallelUploads: 10,
    acceptedFiles: 'image/jpeg,image/png,image/jpg',
    url: `/${url}/update_multiple`,
    init: function () {
      const MAX_FILES_ALLOWED = 10;
      const that = this;
      $(document).on('submit', '#multiple-product-form', function (e) {
        if(that.files.length > 0){
          e.preventDefault();
        e.stopPropagation();
        that.processQueue();
      }
      });

      this.on("sendingmultiple", function (data, xhr, formData) {
        const token = $('meta[name=csrf-token]').attr('content');
        formData.append("authenticity_token", token);
        formData.append("multi[multi_ids]", $('#multi_ids').val());

        if ($("#brandId").val())
          formData.append("multi[brand_id]", $("#brandId").val());

        if ($("#prod_type_id").val())
          formData.append("multi[prod_types_id]", $("#prod_type_id").val());

        if ($("#productType").val())
          formData.append("multi[product_type]", $("#productType").val());

        if ($("#isCanabis").val())
          formData.append("multi[is_canabis]", $("#isCanabis").val());

        if ($("input[name='multi[category_ids][]']").val())
          $(".multi-categories input[name='multi[category_ids][]']").each(function () {
            formData.append("multi[category_ids][]", this.value);
          });

        that.files.map(c =>
          formData.append("multi[files][]", c)
        )
      });
      this.on("addedfiles", function () {
        if (this.files.length > MAX_FILES_ALLOWED) {
          for (let i = this.files.length; i > MAX_FILES_ALLOWED; i--) {
            this.removeFile(this.files[i - 1]);
          }
          alert('You can upload a maximum of 10 images.');
        }
      }),
        this.on('resetFiles', function () {
          this.removeAllFiles(true);
        });
    },
    success: function (file, response) {
      location.reload();
    }
  });

  $(document).on('change', '.mp-actions-tbl-cl input[type="checkbox"]', function (e) {
    const productSelected = $(e.target).prop("checked")
    const productId = $(e.target).attr("product-id")
    const updateAttributesBtn = $('.update-attributes-btn')
    const multiInput = $('#multi_ids')
    if (productSelected) {
      multiSelected.push(productId)
    } else {
      multiSelected = multiSelected.filter(pr => pr !== productId)
    }
    if (multiSelected.length) {
      updateAttributesBtn.show()
    } else {
      updateAttributesBtn.hide()
    }
    console.log("multi_ids input: ", multiSelected)
    multiInput.val(JSON.stringify(multiSelected));
  });

  $('#brand_search')
    .autocomplete({
      appendTo: "#updateAttributesModal",
      source: '/brand_search',
      selectFirst: true,
      minLength: 0,
      scroll: true,
      classes: {
        'ui-autocomplete': 'highlight-autocomplite'
      },
      select(e, ui) {
        $('#brandId').val(ui.item.value);
        $('#brand_search').val(ui.item.label);
        return false;
      }
    })
    .focus(function () {
      // reset result list's pageindex when focus on
      window.pageIndex = 0;
      const obj = $('#brand_search').val();
      $('#brand_search').val('');
      $(this).autocomplete('search', '');
      $('#brand_search').val(obj);
    })

  $("#reset-multiform").on('click', function () {
    resetForm()
  })

  $("#prod_types_id").on('change', function () {
    $("input[name='multi[prod_types_id]']").val($("#prod_types_id").find(':selected').val());
  })

  $("#product_type").on('change', function () {
    $("input[name='multi[product_type]']").val($("#product_type").find(':selected').val());
  })

  $("#is_canabis").on('change', function () {
    $("input[name='multi[is_canabis]']").val($("#is_canabis").find(':selected').val());
  })

  $('.multi-categories').on('click', "input[type='checkbox']", function () {
    var id = this.value;
    var selectedEl = $('.multi-categories').find(`#cat-${id}`);
    if (selectedEl.length > 0) {
      selectedEl.remove();
    } else {
      $('<input>').attr({
        type: 'hidden',
        id: `cat-${id}`,
        value: id,
        name: 'multi[category_ids][]'
      }).appendTo('.multi-categories');
    }
  })

  $(document).on('submit', '#multiple-product-form', function () {
    $("#dZUpload").find('.dz-remove').remove();
    $("input[type='checkbox']").attr("disabled", true);
    $("#is_canabis").attr("disabled", true);
    $("#product_type").attr("disabled", true);
    $("#prod_types_id").attr("disabled", true);
    $("#brand_search").attr("disabled", true);
  });
});

